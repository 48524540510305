<template>
    <div class="dashboard">

        <div class="dash-wrap"> 

            <div class="sidebar">
                <filter-supplier-service @filter="filterDashboard"></filter-supplier-service>
            </div>
            <div class="content">
            
                <div class="widget gap-2">
                    <div class="subtitle">Margen Proveedor</div>
                    <bar-chart :key="key" :labels="suppLabels2" title="s" 
                        :dataChart="suppliersTopKpis" :height="350" v-if="suppliersTopKpis.length > 0 && !showLineChart"></bar-chart>
                </div>

                <div class="widget gap-2">
                    <div class="subtitle">
                        <vs-switch v-model="showLineChart"/>
                        Costo Proveedor - Año
                    </div>
                    <line-chart :key="`line-${key}`" :labels="suppLabels" title="s"
                        :dataChart="suppValies" :height="350" v-if="showLineChart"></line-chart>
                    <bar-chart :key="key" :labels="suppLabels" title="s" 
                        :dataChart="suppValies" :height="350" v-if="kpis.length > 0 && !showLineChart"></bar-chart>
                </div>
                <div class="gap-2 widget">
                    <div class="subtitle">Detalle por año</div>
                    <simple-table :dataTable="kpis"></simple-table>
                </div>
                <div class="gap-2 widget">
                    <div class="subtitle">Agrupado por Proveedor</div>
                    <div class="table-wrapper with-border">
                        <vs-table :data="groupByYears">
                            <template slot="thead">
                                <vs-th sort-key="SupplierCode">Supplier</vs-th>
                                <vs-th sort-key="January">Enero</vs-th>
                                <vs-th sort-key="February">Febrero</vs-th>
                                <vs-th sort-key="March">Marzo</vs-th>
                                <vs-th sort-key="April">Abril</vs-th>
                                <vs-th sort-key="May">Mayo</vs-th>
                                <vs-th sort-key="June">Junio</vs-th>
                                <vs-th sort-key="July">Julio</vs-th>
                                <vs-th sort-key="August">Agosto</vs-th>
                                <vs-th sort-key="September">Septiembre</vs-th>
                                <vs-th sort-key="October">Octubre</vs-th>
                                <vs-th sort-key="November">Noviembre</vs-th>
                                <vs-th sort-key="December">Diciembre</vs-th>
                                <vs-th sort-key="TOTAL">Total</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].SupplierCode" class="freeze">     {{ data[indextr].SupplierCode }} </vs-td>
                                        <vs-td :data="data[indextr].January">  {{ $n(data[indextr].January , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].February"> {{ $n(data[indextr].February, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].March">    {{ $n(data[indextr].March   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].April">    {{ $n(data[indextr].April   , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].May">      {{ $n(data[indextr].May     , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].June">     {{ $n(data[indextr].June    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].July">     {{ $n(data[indextr].July    , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].August">   {{ $n(data[indextr].August  , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].September">{{ $n(data[indextr].September, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].October">  {{ $n(data[indextr].October , 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].November"> {{ $n(data[indextr].November, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].December"> {{ $n(data[indextr].December, 'currency') }} </vs-td>
                                        <vs-td :data="data[indextr].TOTAL">    {{ $n(data[indextr].TOTAL   , 'currency') }} </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>
                </div>
                    
            </div>
        </div>  

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
import simpleTable from '@/components/Tables/SimpleTable'
import filterSupplierService from '@/components/Filters/filterSupplierService'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')


import lineChart  from '@/components/Charts/Line2'
import barChart  from '@/components/Charts/Bar'

export default {
    name: "BookingsView",
    components: {
        modal,
        lineChart,
        barChart,
        simpleTable,
        filterSupplierService,
    },
    data () {
        return {
            key: 1,
            // date: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            disabled: false,
            kpis: [],
            showLineChart: false,
            colors: ["#00ccb3","#1a75d6","#fac71e","#905bff","#f9893e","#fc573b","#df41b6"],
            suppliers: [],
            openSuggestions: true,
            suppliersKpis: [],
            suppLabels: [],
            suppValies: [],
            suppliersTopKpis: [],
            suppLabels2: [],
            suppValies2: [],
            years:[],
            filter: {},
            groupByYears: [],
        
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.date = {
            start: new Date(),
            end:   new Date(),
        }
        this.getSupplierKpis();
        this.getTopSupplierKpis();
        this.getSupplierGroupByYears();

        // this.loadingCharts()
         
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),

        async filterDashboard(options){
            console.log(options)
            this.filter = options
            await this.getSupplierKpis();
            await this.getTopSupplierKpis();
        },
        
        async getSupplierKpis(){
            console.log('getSupplierKpis');
            let response = await BookingService.getSupplierKpis({ 
                'groupBy': "SUPPLIERS_YEARS",
                ...this.filter
            });
            console.log({'response': response})
            if((response.status / 100) == 2){
                this.suppliersKpis = response.data.data
            }
            let tempCostKpi = [];
            this.suppLabels = [];
            this.suppValies = [];
            this.suppliersKpis.forEach( kpi => {
                if(!this.suppLabels.includes(kpi.year)) this.suppLabels.push(kpi.year);
                kpi.data.forEach( item => {
                    let total = 0;
                    for (const key in item) {
                        if(!isNaN(parseFloat(item[key]))){
                            total += parseFloat(parseFloat(item[key]));
                        }
                    }
                    tempCostKpi.push({
                        year: kpi.year,
                        total: total,
                        ...item
                    })
                });

            });
            
            this.kpis = tempCostKpi
            const groupBy = (array, property) => array.reduce((grouped, element) => (
                {
                    ...grouped,
                    [element[property]]: [ ...(grouped[element[property]] || []), element]
                }), {}
            )

            let josue = groupBy(this.kpis,'SupplierCode')
            // // josue.forEach( item => {
            let counter = 0;
            for (const key in josue) {
                let tempArr = []
                josue[key].forEach(item => {
                   tempArr.push(item.total) 
                });
                this.suppValies.push({
                    data:  tempArr,
                    label: key,
                    backgroundColors: this.colors[counter],
                })
                counter++;
            }
            
        },
        async getTopSupplierKpis(){
            console.log('getSupplierKpis');
            let response = await BookingService.getSupplierKpis({ 
                'groupBy': "TOP_SUPPLIERS",
                ...this.filter
            });
            if((response.status / 100) == 2){
                this.suppliersTopKpis = response.data.data
            }
            let tempCostKpi = [];
            let counter = 0;
            this.suppliersTopKpis.forEach( item => {
                tempCostKpi.push({
                    data: [item.TOTA_COST,item.TOTAL_RETAIL, item.MARKUP],
                    label: item.SupplierCode,
                    backgroundColors: this.colors[counter],
                });
                counter++
            });
            this.suppLabels2 = ['COST', 'RETAIL', 'MARKUP']
            this.suppliersTopKpis = tempCostKpi
            
        },

        async getSupplierGroupByYears(){
            let response = await BookingService.getSupplierKpis({ 
                'groupBy': "SUPPLIERS_MONTHS",
                ...this.filter
            });
            if((response.status / 100) == 2){
                this.groupByYears = response.data.data
            }

            // let tempCostKpi = [];
            // let counter = 0;
            // this.suppliersTopKpis.forEach( item => {
            //     tempCostKpi.push({
            //         data: [item.TOTA_COST,item.TOTAL_RETAIL, item.MARKUP],
            //         label: item.SupplierCode,
            //         backgroundColors: this.colors[counter],
            //     });
            //     counter++
            // });
            // this.suppLabels2 = ['COST', 'RETAIL', 'MARKUP']
            // this.suppliersTopKpis = tempCostKpi
            
        },
        
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        
        handleServiceTypeValue(services){
            this.srvCodes = [];
            services.forEach( servivce => {
                this.srvCodes.push(servivce.CODE);
            })
        },

        handleServiceClassValue(services){
            this.classCodes = [];
            services.forEach( servivce => {
                this.classCodes.push(servivce.CODE);
            })
        },
        
    }
}
</script>