<template>
    <div class="table-wrapper with-border">
        <vs-table :data="data">
            <template slot="thead" v-for="(nav, idx) in headers">
                <vs-th :sort-key="nav.key" :class="nav.class" :key="idx">{{nav.text}}</vs-th>
            </template>
            <template slot-scope="{ data }">
                <template :data="tr"  v-for="(tr, indextr) in data">
                    <vs-tr :key="indextr">
                        <vs-td :data="data[indextr].year" class="freeze">     {{ data[indextr].year }} </vs-td>
                        <vs-td :data="data[indextr].SupplierCode" class="freeze">     {{ data[indextr].SupplierCode }} </vs-td>
                        <vs-td :data="data[indextr].January">  {{ $n(data[indextr].January , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].February"> {{ $n(data[indextr].February, 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].March">    {{ $n(data[indextr].March   , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].April">    {{ $n(data[indextr].April   , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].May">      {{ $n(data[indextr].May     , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].June">     {{ $n(data[indextr].June    , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].July">     {{ $n(data[indextr].July    , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].August">   {{ $n(data[indextr].August  , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].September">{{ $n(data[indextr].September, 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].October">  {{ $n(data[indextr].October , 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].November"> {{ $n(data[indextr].November, 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].December"> {{ $n(data[indextr].December, 'currency') }} </vs-td>
                        <vs-td :data="data[indextr].total">    {{ $n(data[indextr].total   , 'currency') }} </vs-td>
                    </vs-tr>
                </template>
            </template>
        </vs-table>
    </div>
</template>
<script>
export default {
    name: "SimpleTable",
    props: {
        dataTable: { type: Array, default: () => []},
    },
    data () {
        return {
            data: [],
            headers: [
                {
                    key: "year",
                    class: "center",
                    text: "Año"
                },
                {
                    key: "SupplierCode",
                    class: "",
                    text: "Supplier"
                },
                {
                    key: "January",
                    class: "",
                    text: "Enero"
                },
                {
                    key: "February",
                    class: "",
                    text: "Febrero"
                },
                {
                    key: "March",
                    class: "",
                    text: "Marzo"
                },
                {
                    key: "Abril",
                    class: "",
                    text: "Abril"
                },
                {
                    key: "May",
                    class: "",
                    text: "Mayo"
                },
                {
                    key: "June",
                    class: "",
                    text: "Junio"
                },
                {
                    key: "July",
                    class: "",
                    text: "Julio"
                },
                {
                    key: "August",
                    class: "",
                    text: "Agosto"
                },
                {
                    key: "September",
                    class: "",
                    text: "Septiembre"
                },
                {
                    key: "Octuber",
                    class: "",
                    text: "Octubre"
                },
                {
                    key: "November",
                    class: "",
                    text: "Noviembre"
                },
                {
                    key: "December",
                    class: "",
                    text: "Diciembre"
                },
                {
                    key: "total",
                    class: "",
                    text: "Total"
                },
            ]
        }
    },
    watch: {
        dataTable (val){
            this.data = val
        },
    }
    
}
</script>